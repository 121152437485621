.home-page .game-queue {
    /* height: 100%; */
}

.game-queue .header-block {
  display: flex;
}

.game-queue .title {
  margin-left: auto;
}

.game-queue .title h1 {
  border-color: var(--color-primary-1);
}

.game-queue .action-block h2 {
  border-color: var(--color-primary-1);
}

.game-queue .currently-playing-toggle{
  --slash-px:  50px;
  --corner-px: 20px;

  clip-path: polygon(
    var(--corner-px) 0,
    calc(100% - var(--slash-px)) 0,
    100% 100%,
    var(--corner-px) 100%,
    0 calc(100% - var(--corner-px)),
    0 var(--corner-px)
  );
}

.game-queue .stats-toggle {
  --slash-px:  50px;
  --corner-px: 20px;
  clip-path: polygon(
    0 0,
    calc(100% - var(--corner-px)) 0,
    100% var(--corner-px),
    100% calc(100% - var(--corner-px)),
    calc(100% - var(--corner-px)) 100%,
    var(--slash-px) 100%
  );
}

.game-queue .queue {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    overflow: auto;

    height: calc(80vh - 3px);

    /* padding: 0px 50px; */
    margin: 0px 50px;

    filter: 
    drop-shadow( 0px 5px 5px black)
    drop-shadow( 0px 5px 5px black);
}


.game-queue .queue .queue-item {
  margin-bottom: 30px;
  --corner-px: 20px;
}

.game-queue .queue .queue-item h1 {
    display: flex;
    /* flex-direction: row; */
    /* position: relative; */
  
    padding: 20px;
    background-color: var(--color-primary-4);
    border: 5px solid var(--color-secondary-1-0);
    
    margin-right: 20px;
    margin-bottom: 0px;
    margin-top: 0px;
    
    /* --corner-px: 20px; */
    clip-path: polygon(
      var(--corner-px) 0%,
      calc(100% - var(--corner-px)) 0%,
      100% var(--corner-px),
      100% calc(100% - var(--corner-px)),
      calc(100% - var(--corner-px)) 100%,
      var(--corner-px) 100%,
      0% calc(100% - var(--corner-px)),  
      0% var(--corner-px)
    );
}

.game-queue .queue .queue-item.wishlist h1 {
  border-color: var(--color-secondary-2-0);
}


.game-queue .queue-item .shift-button {
  background-color: var(--color-primary-4);
  border: 5px solid var(--color-secondary-1-0);
  padding: 5px;

  margin-left: 50%;
  width: 5%;
  transform: translateX(-50%);
  
}

.game-queue .queue-item .up-button{
  border-bottom: none;
  
  clip-path: polygon(
    var(--corner-px) 0,
    calc(100% - var(--corner-px)) 0,
    100% var(--corner-px),
    100% 100%,
    0 100%,
    0 var(--corner-px)
  );
}

.game-queue .queue-item .down-button{
  border-top: none;

  clip-path: polygon(
    0 0,
    100% 0, 
    100% calc(100% - var(--corner-px)),
    calc(100% - var(--corner-px)) 100%,
    var(--corner-px) 100%,
    0 calc(100% - var(--corner-px))
  );
}

.game-queue .queue-item .shift-button i{
  margin-left: 50%;
  transform: 
    translateX(-50%)
    scale(3, 1);
}

.queue-item .queue-buttons{
  
  margin-left: auto;
  display: flex;
  justify-content: center;
  
  /* margin-top: auto;
  margin-bottom: auto; */
}

.queue-item .queue-buttons button{
  padding: 5px;
}

.queue-item .queue-buttons input {
  padding: 5px;
  box-sizing: border-box;
  background-color: var(--color-primary-0);
  border-color: var(--color-primary-4);
  border-style: groove;
  border-right: 0;
}

.queue-item .queue-buttons .set-playing {
  border-left: 0;
}

/* random Select */

.game-queue .random-select {
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;

  filter: 
    drop-shadow( 0px 5px 5px black)
    drop-shadow( 0px 5px 5px black);
}

.game-queue .random-select button{
  /* margin: auto; */
  padding: 5px;
  width: 20%;
}

.game-queue .random-select .random-selected-game{
  background-color: var(--color-primary-4);
  padding: 5px;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 3px solid var(--color-secondary-1-4);

}

.game-queue .random-select .buttons {
  width: 100%;
  display: flex;
  justify-content: center;
}

.game-queue .random-selected-game button{
  /* margin: auto; */
  padding: 5px;
  width: 20%;
}



.game-queue .empty-queue {
  background-color: var(--color-primary-4);
  padding: 20px;
  background-color: var(--color-primary-4);
  border: 5px solid var(--color-secondary-1-0);
  
  margin-right: 20px;
  margin-bottom: 0px;
  margin-top: 0px;
  
  /* --corner-px: 20px; */
  clip-path: polygon(
    var(--corner-px) 0%,
    calc(100% - var(--corner-px)) 0%,
    100% var(--corner-px),
    100% calc(100% - var(--corner-px)),
    calc(100% - var(--corner-px)) 100%,
    var(--corner-px) 100%,
    0% calc(100% - var(--corner-px)),  
    0% var(--corner-px)
  );

  display: flex;
  justify-content: center;

}